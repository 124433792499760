export const BENEFITS_LIST = [
  {
    serial: "01.",
    title: "Preserving digital health records",
  },
  {
    serial: "02.",
    title: "Integrating longitudinal health records",
  },
  {
    serial: "03.",
    title: "Efficient access to health records",
  },
  {
    serial: "04.",
    title: "Easy doctor discovery",
  },
  {
    serial: "05.",
    title: "Instant OPD registration",
  },
];

export const SERVICES_POINTS = [
  {
    title: "OPD Management Software",
  },
  {
    title: "Clinic Management Software",
  },
  {
    title: "Lab Management Software",
  },
];

export const FAQ_QUESTIONS_LIST = [
  {
    id: 0,
    question: "What is the full form of ABHA?",
    answer: "The full form of ABHA is Ayushman Bharat Health Account",
  },
  {
    id: 1,
    question: "How do I access ABHA within our OPD software?",
    answer:
      "Accessing ABHA is simple. Login to your Connect2Clinic app and go to ABHA section and access the details.",
  },
  {
    id: 2,
    question: "What are the primary benefits of using ABHA?",
    answer:
      "ABHA offers numerous benefits, including: Enhanced patient engagement and communication, Streamlined appointment scheduling and management, and Improved access to patient records and history.",
  },
  {
    id: 3,
    question: "Is there any additional cost for using ABHA?",
    answer:
      "The cost of using ABHA is included in your existing subscription for our OPD software. You do not need to pay any extra fees to access and utilize the ABHA integration.",
  },
  {
    id: 4,
    question: "How do patients register for ABHA?",
    answer:
      "Patients can easily register for ABHA through our platform. They need to login to their patient connect2clinic account and they have to click and visit the ABHA section.",
  },
  {
    id: 5,
    question: "Can patients schedule appointments through ABHA?",
    answer:
      "Absolutely! ABHA allows patients to conveniently schedule appointments online. They can log in, check available time slots, and book appointments according to their preferences.",
  },
  {
    id: 6,
    question: "What should I do if I encounter technical issues with ABHA?",
    answer:
      "If you experience any technical issues while using ABHA, please contact our dedicated support team at 18002679899. They will promptly assist you in resolving the issue and ensuring a smooth experience.",
  },
];

export const ABHATEXTCONTANT = {
  heading: "Ayushman Bharat (ABDM)",
  paragraph:
    "Ayushman Bharat Digital Mission (ABDM) is a healthcare system developed and established by the government of India, under the National Health Authority (NHA). The ultimate goal of this service is to launch a perfect system that works in collaboration among all healthcare providers and helps the general public with prompt service.",
};

export const ABHAWHATCONTENT = {
  heading: "What Is ABHA",
  paragraphsone:
    "The ABHA card is an essential part of using ABDM services. It is a government-approved ID that allows users to access and use health services provided by ABDM.",
  paragraphsTwo:
    "Upon successful generation of the ABHA card, individuals can utilize it as a means of identification. Additionally, the PHR app enables secure sharing of health records with healthcare providers, ensuring privacy and consent are maintained.",
};

export const ABOUTABHA = {
  HEADING: " How to Create",
  HEADING2: "ABHA Card or Health ID Card?",
  SUBTITLE1: "Enter the Aadhar number and verify it with OTP",
  SUBTITLE2:
    " Choose ABHA address and Confirm & your ABHA card has been created",
  SUBTITLE3:
    " Provide consent for PHR app & your account is ready to receive & store health records",
};

export const ABOUTABHAMOBILEVIEW = {
  HEADING: " How to Create",
  HEADING2: "ABHA Card or Health ID Card?",
  SUBTITLE1: "Enter the Aadhar number and ",
  SUBHEADINGSUB1: "verify it with OTP",
  SUBTITLE2: " Choose ABHA address and Confirm &",
  SUBHEADINGSUB2: "your ABHA card has been created",
  SUBTITLE3: " Provide consent for PHR app & your account is ",
  SUBHEADINGSUB3: "ready to receive & store health records",
};

export const HEALTHACCOUNTAI = {
  HEALTHHEADING: "  Ayushman Bharat Health Account (ABHA)",
  HEALTHSUBHEADING:
    " The Ayushman Bharat Health Account (ABHA) is a 14-digit unique identification number that is generated when an account is created on the ABDM portal. This account serves as the gateway to accessing healthcare services provided by ABDM.",
  HEALTHSUBHEADING2:
    "The ABHA makes it easy for users to store and access their personal health records securely and privately. Users can access their records from any device, making it a convenient way to manage their health information.",
  HEALTHSUBHEADING3:
    "  At the time of registration for ABHA, the ABHA address or PHR address is generated, which is crucial for the further creation of the ABHA card.",
};

export const SERVICEABHA = {
  HEADING: " Using Connect2Clinic to Access ABDM Services",
  SUBHEADING:
    " Experience streamlined health services via Connect2Clinic seamlessly integrated with ABDM. Our all-in-one software drives productive healthcare digitization, empowering users nationwide. This software supports the",
  SUBHEADING2: "Client with the following services:",
};

export const ABHAFAQ = {
  FAQHEADING: "  FAQ - ABHA Integration",
  FAQSUBHEADING:
    "   Welcome to our FAQ page for using ABHA, integrated seamlessly into our OPD software platform. Here, we’ve compiled answers to common questions you might have about utilizing ABHA to enhance your experience. If you don’t find the information you’re looking for here, feel free to reach out to our support team for personalized assistance.",
};

export const ABHATOPCONTANT = {
  TOPHEADING: " EMPOWER YOUR",
  TOPHEADING2: "HEALTH JOURNEY",
  TOPHEADING3: "WITH",
  TOPHEADING4: "ABDM",
  TOPHEADING5: " INTEGRATION",
};

export const AADHAR_VALIDATION_ERROR = {
  FIRST_ERROR: "Enter valid Aadhaar Number",
  SECOND_ERROR: "Aadhar not verified. Please try again.",
  THIRD_ERROR: "Try again after 30 minutes",
};

export const OTP_VALIDATION_ERROR = {
  FIRST_ERROR: "Enter valid OTP",
  SECOND_ERROR: "OTP verification failed.",
  THIRD_ERROR:
    "Maximum OTP Resend attempts Exceeded. Please try again in 30 mins.",
};
